import axios from 'axios';

type SetStateFunction = (updateFunction: (prevState: string) => string) => void;

export const stream = {
  get: async (path: string, setState: SetStateFunction): Promise<Response | null> => {
    const url = `https://api.${window.location.hostname}${path}`;
    const token = localStorage.getItem('_auth');
    const authHeader = token ? `Bearer ${token}` : null;

    try {
      const response = await fetch(url, { headers: { 'Authorization': authHeader } });
      await processResponse(response, setState);
      return response
    } catch (error) {
      console.error('Error in GET request:', error);
      return null;
    }
  },

  post: async (path: string, body: any, setState: SetStateFunction): Promise<Response | null> => {
    const url = `https://api.${window.location.hostname}${path}`;
    const token = localStorage.getItem('_auth');
    const authHeader = token ? `Bearer ${token}` : null;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Authorization': authHeader,
        },
      });
      await processResponse(response, setState);
      return response
    } catch (error) {
      console.error('Error in POST request:', error);
      return null
    }
  },
};

async function processResponse(response: Response, setState: SetStateFunction): Promise<void> {
  if (!response.ok || !response.body) {
    throw new Error(response.statusText);
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  while (true) {
    const { value, done } = await reader.read();
    if (done) {
      break;
    }
    const decodedChunk = decoder.decode(value, { stream: true });
    setState(prev => prev + decodedChunk);
  }
}

// Usage:
// const response = await api.get('/endpoint');
const api = axios.create({
  baseURL: 'https://api.' + window.location.hostname,
});

//Set the auth token for any request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('_auth');
    const authHeader = token ? `Bearer ${token}` : null;
    
    if (authHeader) {
      config.headers['Authorization'] = authHeader;
    } else {
      delete config.headers['Authorization'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
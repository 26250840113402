import React, { useEffect, useState } from 'react';
import api from '../Api'; //Use this to make API calls (e.g. await api.get("/endpoint"))

const Dashboard = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/currentJson");
                setData(response.data.messages);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {data.map((message, index) => (
                <div key={index}>
                    <p>Role: {message.role}</p>
                    <p>Content: {message.content}</p>
                    <hr />
                </div>
            ))}
        </div>
    );
};

export default Dashboard;
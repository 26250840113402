import React from 'react';
import { Route as SwizzleRoute, Routes as SwizzleRoutes } from 'react-router-dom';
import SwizzleHomePage from './pages/SwizzleHomePage';
import SwizzlePrivateRoute from './SwizzlePrivateRoute';
import Upload from './pages/Upload';
import Dashboard from './pages/Dashboard';

const RouteList = () => {
  return (
    <SwizzleRoutes>
  <SwizzleRoute path="/" element={<SwizzleHomePage />} />
  <SwizzleRoute path="/upload" element={<Upload />} />
  <SwizzleRoute path="/dashboard" element={<Dashboard />} />
</SwizzleRoutes>
  )
};

export default RouteList;

import React, { useState } from 'react';
import api from '../Api';

const Upload = () => {
    const [json, setJson] = useState("");
    const [message, setMessage] = useState("");

    const handleJsonSubmit = async () => {
        try {
            const response = await api.post("/saveJsonL", { jsonl: json });
            setMessage(response.data.message);
        } catch (error) {
            console.error(error);
            setMessage("Error: " + error.message);
        }
    };

    return (
        <div>
            <textarea placeholder={"Save new JSON"} value={json} onChange={(e) => setJson(e.target.value)} />
            <button onClick={handleJsonSubmit} className='btn btn-primary'>Submit JSON</button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Upload;